import HeaderTopDashboard from '@/components/Layout/HeaderTopDashboard.vue'
import TaskListService from '@/services/assistant/task-list.service.js'
import { Task } from '@/models/assistant/task.js'
import RoutesService from '@/services/whatsapp/routes.service'
import { Subscriptions } from '@/classes/helpers/Subscriptions'
import AiService from '@/services/ai/ai.service.js'

export default {
  name: 'TaskList',
  components: {
    HeaderTopDashboard,
  },
  props: { },
  data () {
    return {
      items: [],
      itemsPerPage: 5,
      pageCount: 0,
      page: 1,
      total: 0,
      disabledPagination: true,
      options: {},
      searchTerm: '',
      totalItems: 0,
      debouncedInput: '',
      timeout: null,
      removeTaskDialog: false,
      loading: false,
      currentTask: undefined,
      completeTaskDialog: false,
      pendingTaskDialog: false,
      statusFilter: null,
      typeFilter: null,
      channelFilter: null,
      loadingWebUrlContent: false,
      webUrlContent: '',
      webUrlDialog: false,
    }
  },
  methods: {
    showInfo (item) {
      this.currentTask = item
      if (this.currentTask.task_id === Task.KNOWLEDGE_DROPPED) {
        this.getWebUrlContent()
      } else if ([Task.LACK_OF_INFORMATION, Task.INCORRECT_RESPONSE].includes(this.currentTask.task_id)) {
        switch (this.currentTask.channel) {
          case Task.TASK_CHANNEL_WHATSAPP:
            this.goToWhatsAppConversation()
            break
          case Task.TASK_CHANNEL_WEBCHAT:
            this.goToWebChatConversation()
            break
        }
      }
    },

    goToWebChatConversation () {
      localStorage.setItem('load-conversation-from-task', JSON.stringify(this.currentTask))
      this.$router.push({ name: 'web-chat' })
    },

    goToWhatsAppConversation () {
      localStorage.setItem('load-conversation-from-task', JSON.stringify(this.currentTask))
      this.$router.push({ name: 'WhatsappInboxPage' })
    },

    getWebUrlContent () {
      this.webUrlDialog = true
      this.loadingWebUrlContent = true
      this.webUrlContent = ''
      AiService.getWebUrlContent({ url: this.currentTask.url }).then((data) => {
        this.webUrlContent = data.content
      }).catch(() => {
        this.loadingWebUrlContent = false
      }).finally(() => {
        this.loadingWebUrlContent = false
      })
    },
    completeTask () {
      this.loading = true
      TaskListService.complete({ id: this.currentTask.id }).then(() => {
        this.loading = false
        this.completeTaskDialog = false
        this.getTasksList()
      })
    },
    pendingTask () {
      this.loading = true
      TaskListService.pending({ id: this.currentTask.id }).then(() => {
        this.loading = false
        this.pendingTaskDialog = false
        this.getTasksList()
      })
    },

    prepareCompleteTask (item) {
      this.currentTask = item
      this.completeTaskDialog = true
    },
    preparePendingTask (item) {
      this.currentTask = item
      this.pendingTaskDialog = true
    },
    prepareRemoveTask (item) {
      this.currentTask = item
      this.removeTaskDialog = true
    },
    removeTask () {
      this.loading = true
      TaskListService.remove({ id: this.currentTask.id }).then(() => {
        this.loading = false
        this.removeTaskDialog = false
        this.getTasksList()
      })
    },
    getParams () {
      const page = this.items.length === 1 && this.options.page > 1
      ? this.options.page - 1
      : this.options.page

      const params = {
        page: page,
        perPage: this.options.itemsPerPage,
        searchTerm: this.searchTerm,
        statusFilter: this.statusFilter,
        typeFilter: this.typeFilter,
        channelFilter: this.channelFilter,
      }

      for (let i = 0; i < this.options.sortBy.length; i++) {
        params['sortBy[' + i + '][field]'] = this.options.sortBy[i]
        params['sortBy[' + i + '][dir]'] = this.options.sortDesc[i]
          ? 'asc'
          : 'desc'
      }
      return params
    },
    getTasksList () {
      const data = this.getParams()
      this.disabledPagination = true

      TaskListService.getTasksList(data).then((response) => {
        this.items = response.data
        this.itemsPerPage = parseInt(response.per_page)
        this.page = response.current_page
        this.totalItems = response.total
        this.disabledPagination = false
      })
    },
    getTypeTranslated (type) {
        switch (type) {
            case Task.KNOWLEDGE_DROPPED:
                return this.$t('Conocimiento caido')
            case Task.LACK_OF_INFORMATION:
                return this.$t('Falta de información')
            case Task.INCORRECT_RESPONSE:
                return this.$t('Respuesta incorrecta')
            case Task.VALIDATE_RESPONSE_BY_AN_AGENT:
                return this.$t('Validar respuesta por un agente')
            default:
                return this.$t('tipo desconocido')
        }
    },
  },
  computed: {
    canWebChat () {
      return this.$store.getters['auth/getUser'].enable?.wchat
    },
    canWhatsapp () {
      return this.$store.getters['auth/getUser'].enable?.wa
    },
    channelTypes () {
      const options = []

      if (this.canWebChat && this.canWhatsapp) {
        options.push({ text: this.$t('Todos'), value: null })
      }

      if (this.canWhatsapp) {
        options.push({ text: 'WhatsApp', value: 1 })
      }

      if (this.canWebChat) {
        options.push({ text: 'WebChat', value: 2 })
      }

      return options
    },

    taskTypes () {
      return [
        { text: this.$t('Todas'), value: null },
        { text: this.$t('Conocimiento caído'), value: Task.KNOWLEDGE_DROPPED },
        { text: this.$t('Falta de información'), value: Task.LACK_OF_INFORMATION },
        { text: this.$t('Respuesta incorrecta'), value: Task.INCORRECT_RESPONSE },
        { text: this.$t('Validar respuesta por un agente'), value: Task.VALIDATE_RESPONSE_BY_AN_AGENT },
      ]
    },
    taskStatuses () {
        return [
            { text: this.$t('Todos'), value: null },
            { text: this.$t('Pendiente'), value: Task.TASK_STATUS_PENDING },
            { text: this.$t('Completado'), value: Task.TASK_STATUS_COMPLETE },
        ]
    },
    headers () {
      return [
        {
          text: this.$t('Nombre'),
          value: 'name',
        },
        {
          text: this.$t('Tipo'),
          value: 'task_id',
        },
        {
          text: this.$t('Canal'),
          value: 'channel',
        },
        {
          text: this.$t('Mensaje'),
          value: 'message',
        },
        {
          text: this.$t('Fecha de creación'),
          value: 'created_at',
        },
        {
          text: this.$t('Estado'),
          value: 'status',
        },
        {
          text: this.$t('Acciones'),
          value: 'actions',
          sortable: false,
        },
      ]
    },
    searchTermHelper: {
      get: function () {
        return this.debouncedInput
      },
      set: function (val) {
        this.debouncedInput = val
        if (this.timeout !== null) {
          clearTimeout(this.timeout)
        }
        this.timeout = setTimeout(() => {
          this.searchTerm = val
          this.options.page = 1
        }, 700)
      },
    },
    params (nv) {
      return {
        ...this.options,
        query: this.searchTerm,
        statusFilter: this.statusFilter,
        typeFilter: this.typeFilter,
        channelFilter: this.channelFilter,
      }
    },
  },
  watch: {
    params: {
      handler () {
        this.getTasksList()
      },
      deep: true,
    },
  },
  mounted () {
    setTimeout(() => {
      this.channelFilter = this.channelTypes[0].value
    }, 1000)
  },
  created () {
    RoutesService.getCurrentUserAllRoutes()
    .then((routes) => {
      Subscriptions.checkSubscription(
        Subscriptions.CHATBOTS,
        () => {
          this.$router.push({ name: this.$route.name, query: this.$route.query }).catch(() => { this.$forceUpdate() })
        },
        () => this.$router.push({ name: 'ChatBotsSubscriptionNeeded' }).catch(() => { this.$forceUpdate() }),
      )
    })
  },
}
